<template>
  <main id="content" role="main">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative z-index-2 bg-img-hero overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/img/events/apidays-live-hongkong/images/apidays-banner-light.jpg') + ')'}">
      <!-- Breadcrumb -->
      <div class="container space-top-2 mt-md-6 pl-0">
        <div class="row justify-content-sm-between mb-5">
          <div class="col-md">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item">
                  <!-- <a href="/events"> -->
                  <router-link :to="{ path: '/events' }">
                    Events
                  </router-link>
                  <!-- </a> -->
                </li>
                <li class="breadcrumb-item">
                  <!-- <a href="/events/apidays-live-hongkong-tc"> -->
                  <router-link :to="{ path: '/events/apidays-live-hongkong-tc' }">
                    Apidays Live 香港站 2020
                  </router-link>
                  <!-- </a> -->
                </li>
                <li class="breadcrumb-item active" aria-current="page">Apidays 2020 精彩回顧</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Title -->
      <div class="container space-bottom-2 position-relative">
        <div class="w-lg-60 text-center mx-lg-auto">
          <div class="mb-5">
            <img class="img-fluid mb-5 max-w-19rem" src="@/assets/img/events/apidays-live-hongkong/organisers/apidays-live-hk-color.png">
            <h1 class="mb-3">Apidays 2020 精彩回顧</h1>
            <p class="lead">觀看回放影片和發掘活動的其他資源</p>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <div style="background-color: rgb(249 254 255)">
      <div class="container space-2">
        <input v-for="item in category_list" :id="item.category" :key="item.category" v-model="selected_category" type="radio" name="categories" :value="item.category">
        <ol class="filters-btn" style="margin-left: 1rem;">
          <li v-for="item in category_list" :key="item.category">
            <label class="btn btn-sm btn-soft-secondary btn-pill py-2 px-3 m-1 mb-2" :class="{'selectedCategory': selected_category === item.category}" :for="item.category">{{ item.category_title }}</label>
          </li>
        </ol>
        <ol class="filters-container" style="padding: 0 1.5rem 1.5rem 1.5rem">
          <div class="row">
            <li v-for="item in filtered_category_list" :key="item.url" class="col-md-6 col-lg-4 mb-5 filters-item" :data-category="item.category">
              <ApidaysRecapCard :item="item" />
            </li>
          </div>
        </ol>
      </div>
    </div>

  </main>
</template>

<script>
import ApidaysRecapCard from '@/components/apidays-recap-card'
import { mapGetters } from 'vuex'
export default {
  name: 'Apidays2020RecapTC',
  components: {
    ApidaysRecapCard
  },
  data() {
    return {
      category_list: [],
      filtered_category_list: [],
      apidays_2020_recap_list: [],
      selected_category: 'All'
    }
  },
  computed: {
    ...mapGetters([
      'apidays2020recap'
    ])
  },
  created() {
    this.apidays_2020_recap_list = this.$store.getters.apidays2020recap.apidays_2020_recap_list
    this.filtered_category_list = this.apidays_2020_recap_list.filter(item => {
      return item.hidden !== true
    })
    this.getCategoryList(this.filtered_category_list)
  },
  methods: {
    getCategoryList(list) {
      function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const titleA = a.category_title.toUpperCase()
        const titleB = b.category_title.toUpperCase()

        let comparison = 0
        if (titleA > titleB) {
          comparison = 1
        } else if (titleA < titleB) {
          comparison = -1
        }
        return comparison
      }
      let array = []
      const temp = []
      for (let i = 0; i < list.length; i++) {
        const object = {
          category_title: '',
          category: ''
        }
        object.category_title = list[i].category_title
        object.category = list[i].category
        array = array.concat(object)
      }
      const map = new Map()
      for (const item of array) {
        if (!map.has(item.category_title)) {
          map.set(item.category_title, true)
          temp.push({
            category_title: item.category_title,
            category: item.category
          })
        }
      }
      temp.sort(compare)
      this.category_list = [{ category_title: 'All', category: 'All' }]
      this.category_list = this.category_list.concat(temp)
    }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Apidays 2020 精彩回顧 | Apidays Live 香港站 2020 | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/events/apidays-live-hongkong-tc/recap' }
      ],
      meta: [
        { name: 'description', content: '屢獲殊榮的一站式API平台服務，連接數字業務以擁抱開放API經濟' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Apidays 2020 精彩回顧 | Apidays Live 香港站 2020 | beNovelty' },
        { property: 'og:description', content: '觀看 Apidays Live 香港站 2020 回放影片和發掘活動的其他資源' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/apidays-live-hongkong.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/events/apidays-live-hongkong-tc/recap' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../../assets/vendor/slick-carousel/slick/slick.css");
</style>
